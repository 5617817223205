import {
    useToast,
    useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useContext } from 'react'
import * as yup from "yup";
import CRUD from '../composants/Crud';
import { GlobalContext } from '../../context';




const PrixTypeReservation = ({
    getPrixTypeReservations,
    typeReservations
}) => {

    const toast = useToast()
    // const [isUpdate, setisUpdate] = React.useState(false)
    const [typeReservation, setTypeReservation] = React.useState([])
    const [objetTypeReservation, setObjetTypeReservation] = React.useState({})
    const context = useContext(GlobalContext)
    const apiService =context.service
    const [state, setState] = React.useState({ champsInitial: [], listeColumn: [] })
    const schema = yup.object().shape({
        montant: yup.number().required("Entrer le montant"),
        type_chambre_id: yup.string().required("Le type de chambre est obligatoire"),
        type_reservation_id: yup.string().required("Le type de chambre est obligatoire"),

    });



    useEffect(() => {
        // setTypeChambre(typeChambres.map(item => ({
        //   key: item.id,
        //   value: item.libelle
        // })))
        // let obj={}
        // typeChambres.map(item=>
        //   {
        //     const id={}
        //     console.log("obj", item)
        //     id[`${item.id}`]=item.libelle
        //     obj=Object.assign(obj, id)


        // })
        // setObjetTypeChambre(obj)
        console.log("state", state)

    }, [typeReservation])
    useEffect(() => {
        const tab = typeReservations.map(item => ({
            key: item.id,
            value: item.libelle
        }))
        let objTypeChambre={}
        let typeChambreTab = []
        apiService.getAll(context?.token, "typechambre", (resp) => {
            const data=resp.data.data
            console.log("data===>", data)
            data?.map(item =>{ 
                typeChambreTab.push({
                key: item.id,
                value: item.libelle
            })
            const id = {}
            id[`${item.id}`] = item.libelle
            objTypeChambre = Object.assign(objTypeChambre, id)
        
        })
            


          },
            (error) => {
              if (error?.response?.status === 401) {
                context?.logout()
    
              }
              console.error("error====>", error);
            })
        const champsInitial =
            [
                {
                    libelle: null,
                    icon: null,
                    liste:
                        [

                            {
                                label: "Type Reservation",
                                placeholder: "Selectionner le type reservation ",
                                type: "select",
                                name: 'type_reservation_id',
                                liste: tab,
                                isRequired: true
                            },
                            {
                                label: "Type chambre",
                                placeholder: "Selectionner le type chambre",
                                type: "select",
                                name: 'type_chambre_id',
                                liste: typeChambreTab,
                                isRequired: true
                            },
                            {
                                label: "Montant",
                                placeholder: "Entrer le montant ",
                                type: "number",
                                name: 'montant',
                                isRequired: true
                            },
                            {
                                label: "Status",
                                placeholder: "Choisir le status",
                                type: "select",
                                name: 'status',
                                liste: [{ key: false, value: "deactiver" }, { key: true, value: "Activer" }],
                                isRequired: true,
                                defaultValue: false
                    
                            }

                        ]
                }, 
            ]
        setTypeReservation(tab)
        let obj = {}
        typeReservations.map(item => {
            const id = {}
            console.log("obj", item)
            id[`${item.id}`] = item.libelle
            obj = Object.assign(obj, id)


        })
        const listeColumn = [

            {
                title: 'Type Reservation',
                field: 'type_reservation_id',
                lookup: obj
            },
            {
                title: 'Type chambre',
                field: 'type_chambre_id',
                lookup: objTypeChambre
            },
            {
                title: 'Montant',
                field: 'montant',
            },
            
        ]
        setState({ champsInitial: champsInitial, listeColumn: listeColumn })
        setObjetTypeReservation(obj)
    }, [typeReservations])
    return (
        <>
            <CRUD
                schema={schema}
                champs={state.champsInitial}
                columns={state.listeColumn}
                path="prixtypechambre"
                getData={getPrixTypeReservations}
                hasRightSave={true}
                hasRightDelete={true}
                hasRightUpdate={true}
                title="Chambre"
                icon={null}
            />



        </>
    )
}
export default PrixTypeReservation
