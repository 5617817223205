import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  useColorModeValue,
  ScaleFade
} from '@chakra-ui/react'

function DataAccordions({ data }) {
  const { isOpen, onToggle } = useDisclosure()
  const bg=useColorModeValue('brand.primary', 'brand.primary')
  return (
    <Box
      minW={{ base: "full", md: "90%", lg: "90%" }}
      mx={{ base: "1", md: "2%", lg: "2%" }}
      borderWidth='2px'
      borderRadius='lg'
      borderColor="brand.primary"
    >
      <Accordion defaultIndex={[0]} >
        {data.map((tab, index) => (

          <AccordionItem key={index} isExpanded={onToggle}>
            <h2>
              <AccordionButton isExpanded={onToggle} _expanded={{ bg: bg, color: "white" }}
              w={{ base: "full", md: "full" }}
              p={{ base: 4, md: 3 }}
              >
                <Box flex="1" textAlign="left">
                  {tab.label}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <ScaleFade initialScale={0.5} in={true}>
                {tab.content}
              </ScaleFade>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  )
}

export default DataAccordions