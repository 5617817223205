import {
  useToast,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useContext } from 'react'
import * as yup from "yup";
import CRUD from '../composants/Crud';




const Departement = ({
  regions,
  getDepartements
}) => {

  const toast = useToast()
  // const [isUpdate, setisUpdate] = React.useState(false)
  const [region, setRegion] = React.useState([])
  const [objetRegion, setObjetRegion] = React.useState({})
  const schema = yup.object().shape({
    code: yup.string().required("Le code est obligatoire"),
    libelle: yup.string().required("Le libelle est obligatoire"),
    region_id: yup.string().required("La région est obligatoire"),
   
  });
  const champsInitial =
    [
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
              label: "Libelle",
              placeholder: "Entrer le libelle",
              type: "text",
              name: 'libelle',
              isRequired: true
            },
            {
              label: "Code",
              placeholder: "Entrer le code ",
              type: "text",
              name: 'code',
              isRequired: true
            },
            {
              label: "Région",
              placeholder: "Selectionner la région",
              type: "select",
              name: 'region_id',
              liste:region,
              isRequired: true
            }

          ]
      },


    ]
  const listeColumn = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    },
    {
      title: 'Région',
      field: 'region_id',
      lookup: objetRegion
    }
  ]

  useEffect(() => {
    setRegion(regions.map(item => ({
      key: item.id,
      value: item.libelle
    })))
    let obj={}
    regions.map(item=>
      {
        const id={}
        console.log("obj", item)
        id[`${item.id}`]=item.libelle
        obj=Object.assign(obj, id)
      
      
    })
    setObjetRegion(obj)
   

  }, [regions])
  useEffect(() => {
    setRegion(regions.map(item => ({
      key: item.id,
      value: item.libelle
    })))
    let obj={}
    regions.map(item=>
      {
        const id={}
        console.log("obj", item)
        id[`${item.id}`]=item.libelle
        obj=Object.assign(obj, id)
      
      
    })
    console.log("obj", obj)
    setObjetRegion(obj)
  }, [])
  return (
    <>
      <CRUD
        schema={schema}
        champs={champsInitial}
        columns={listeColumn}
        path="departements"
        getData={getDepartements}
        hasRightSave={true}
        hasRightDelete={true}
        hasRightUpdate={true}
        title="Departement"
        icon={null}
      />



    </>
  )
}
export default Departement
