
import * as React from 'react'
import { useState, useEffect } from 'react'
import Candidature from './Candidature'
import Poste from './Poste'
import DataTabs from '../composants/DataTabs'
import SidebarWithHeader from '../home/sidebard'


const Recrutement = (props) => {
  const [isUpdate, setIsUpdate] = useState({
    typeChambre: false,
  })
  const [postes, setPostes] = useState([])
  const [chambres, setChambres] = useState([])

  const tabRecrutement = [
    {
      label: 'Gestion Poste',
      content: <Poste
        getPostes={setPostes}
      />,
    },
    {
      label: 'Gestion candidature',
      content: <Candidature
      postes={postes}
      // getPostes={setPostes}
      />,
    }
    //   {
    //     label: 'Gestion commune',
    //     content:<Commune
    //     villesIn={villes}
    //     />,
    // },
  ]

  useEffect(() => {

  }, [postes])




  return (
    <SidebarWithHeader>
      <DataTabs data={tabRecrutement} />
    </SidebarWithHeader>

  )
}
export default Recrutement