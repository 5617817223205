import React from 'react'
import {
    Box,
    HStack,
    Center,
    Heading,
    Image
} from '@chakra-ui/react'

 function LogoSA({
    color,
    bg
}) {
    return (
        <section>
            <Box
                // borderColor="vert.900"
                // shadow='md'
                // color="white"
                // bg="vert.900"
                as='button'
                borderRadius='md'
                p={2}

            >
                <Center>
                    <HStack >
                        <Box>
                            <Heading size='2xl'>
                                <h1> 2A </h1>
                            </Heading>
                        </Box>
                        <Box
                            bg="white"
                            color="brand.primary"
                            as='button' borderRadius='md'
                            pt={3}
                        >

                            <Heading size='2xl' >
                                <h1>SISTA</h1>
                            </Heading>

                        </Box>
                    </HStack>
                </Center>
            </Box>
        </section>
    )
}

export default LogoSA