
import * as React from 'react'
import { InputField } from './InputField';
import { SelectField } from './SelectField';
import { RadioField } from './RadioField';
import { CheckBoxField } from './CheckBoxField';
import { TextAreaField } from './TextAreaField';
import { MultiSelectField } from './MultiSelect';
import { NumberField } from './NumberField';
import { InputNumberField } from '../../InputNumberField';
import { ImageField } from './ImageField';
import { FichierField } from './FichierField';

export const Field = React.forwardRef((props, ref) => {
  const { type } = props
  switch (type) {
    case "text":
    case 'email':
    case 'password':
    case 'tel':
    case 'date':
    case 'time':
      
      return <InputField {...props} />;
    case 'image':
      return <ImageField {...props} />;
    case 'fichier':
      return <FichierField {...props} />;
    case "select":
      return <SelectField {...props} />;
    case "multiselect":
      return <MultiSelectField {...props} />;
    case 'radio':
      return <RadioField {...props} />;
    case "checkbox":
      return <CheckBoxField {...props} />;
    case 'textarea':
      return <TextAreaField {...props} />
    case 'number':
      return <NumberField {...props} />
    default:
      return ""
  }
})
Field.displayName = 'Field'
