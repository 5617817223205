import {
  useToast,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useContext } from 'react'
import * as yup from "yup";
import "yup-phone";
import CRUD from '../composants/Crud';
import { GlobalContext } from '../../context';




const Candidature = ({
  postes,
  getPostes
}) => {

  const toast = useToast()
  // const [isUpdate, setisUpdate] = React.useState(false)
  const [listePostes, setListesPostes] = React.useState([])
  const [objetPostes, setObjetPostes] = React.useState({})
  const context = useContext(GlobalContext)
  const apiService = context.service
  const [state, setState] = React.useState({ champsInitial: [], listeColumn: [] })
  const schema = yup.object().shape({
    nom: yup.string().required("Le nom  est obligatoire"),
    prenom: yup.string().required("Le prenom  est obligatoire"),
    mail: yup.string().email().required("Le mail est obligatoire"),
    telephone: yup.string().phone().required("Le telephone est obligatoire"),
    poste_id: yup.string().required("Le poste  est obligatoire"),
    cv_id: yup.string().required("Le CV  est obligatoire"),
    motivation_id: yup.string().required("La lettre de motivation  est obligatoire"),
    diplome_id: yup.string().required("Le dernier diplôme  est obligatoire"),

  });



  useEffect(() => {
    // setTypeChambre(typeChambres.map(item => ({
    //   key: item.id,
    //   value: item.libelle
    // })))
    // let obj={}
    // typeChambres.map(item=>
    //   {
    //     const id={}
    //     console.log("obj", item)
    //     id[`${item.id}`]=item.libelle
    //     obj=Object.assign(obj, id)


    // })
    // setObjetTypeChambre(obj)
    console.log("state", state)

  }, [listePostes])
  useEffect(() => {
    console.log("Poste", postes)
    const tab = postes.map(item => ({
      key: item?.id,
      value: item?.libelle
    }))
    console.log("tabbbb", tab)

    const champsInitial =
      [{
        libelle: "Informations Personnelles",
        icon: null,
        liste:
          [

            {
              label: "Nom",
              placeholder: "Entrer votre nom ",
              type: "text",
              name: 'nom',
              isRequired: true
            },
            {
              label: "Prenom",
              placeholder: "Entrer votre prenom ",
              type: "text",
              name: 'prenom',
              isRequired: true
            },
            {
              label: "Mail",
              placeholder: "Entrer votre mail ",
              type: "email",
              name: 'mail',
              isRequired: true
            },
            {
              label: "Téléphone",
              placeholder: "Entrer votre numéro téléphone ",
              type: "text",
              name: 'telephone',
              isRequired: true
            },
            {
              label: "Poste",
              placeholder: "------Selectionner---------",
              type: "select",
              name: 'poste_id',
              liste: tab,
              isRequired: true,
              defaultValue: false

            }

          ]
      },

      {
        libelle: "Documents à fournir",
        icon: null,
        liste:
          [

            {
              label: "CV",
              placeholder: "Entrer votre numéro téléphone ",
              type: "fichier",
              name: 'cv_id',
              isRequired: true
            },
            {
              label: "Dernier Diplôme",
              placeholder: "Entrer votre numéro téléphone ",
              type: "fichier",
              name: 'diplome_id',
              isRequired: true
            }, {
              label: "Lettre de motivation",
              placeholder: "Entrer votre numéro téléphone ",
              type: "fichier",
              name: 'motivation_id',
              isRequired: true
            },

          ]
      },

      ]
    
    let obj = {}
    postes.map(item => {
      const id = {}
      console.log("obj", item)
      id[`${item.id}`] = item?.libelle
      obj = Object.assign(obj, id)


    })
    const listeColumn = [

      {
        title: 'Poste',
        field: 'poste_id',
        lookup: obj
      },
      {
        title: 'Nom',
        field: 'nom',
      },
      {
        title: 'Prenom',
        field: 'prenom',
      },
      {
        title: 'Mail',
        field: 'mail',
      },
      {
        title: 'Telephone',
        field: 'telephone',
      },
      ,
      {
        title: 'Date',
        field: 'date',
        type: 'date',
        render: rowData =>new Date(rowData?.date).toLocaleDateString('fr-FR')
      },

    ]
    setState({ champsInitial: champsInitial, listeColumn: listeColumn })
    setListesPostes(tab)
    setObjetPostes(obj)
  }, [postes])
  return (
    <>
      <CRUD
        schema={schema}
        champs={state.champsInitial}
        excludeChamps={["date", "motivation", "cv", "diplome"]}
        columns={state.listeColumn}
        path="candidat"
        getData={()=>null}
        hasRightSave={true}
        hasRightDelete={true}
        hasRightUpdate={true}
        title="Candidature"
        icon={null}
      />



    </>
  )
}
export default Candidature
