import React, {useContext} from 'react'
import Datatable from '../composants/Crud/Datatable'
import { BsListCheck } from 'react-icons/bs'
import { GlobalContext } from '../../context'
import { useDisclosure } from '@chakra-ui/react'
import ReservationForm from './ReservationForm'

function ListeReservation() {
      const { isOpen, onClose, onToggle } = useDisclosure()
  const [data, setData] = React.useState([])
  const [ item, setItem] = React.useState({})
  const context = useContext(GlobalContext)
  const apiService = context.service
  const { service, token, dataUser, logout } = context
  const onGet = (query, resolve) => {
      let url="reservation"
      let data={
        data: [],
        page: 0,
        totalCount: 0,
      }
      if (query.pageSize) {
        url += '?limit=' + query.pageSize
        url += '&offset=' + (query.page? query.page*query.pageSize: 0)
      }
      apiService.getAll(token, url, (resp) => {
        data={
          data: resp.data.data,
          page: query.page,
          totalCount: resp.data.pagination.total - 1,
        }
        resolve(data)
        setData(resp.data.data)
      },
        (error) => {
          if (error?.response?.status === 401) {
            logout()

          }
          console.error("error====>", error);
        })
  }
  const columns= [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    }
  ]
  return (
    <>
        <Datatable
              icon={<BsListCheck/>}
              title={"Liste reservation"}
              data={data}
              onGet={onGet}
              columns={columns}
              setData={setData}
              setItem={setItem}
              onToggle={onToggle}
            />
            <ReservationForm
            isOpen={isOpen}
            onClose={onClose}
            />
    </>
  )
}

export default ListeReservation