import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    IoAnalyticsSharp,
    IoLogoBitcoin,
    IoSearchSharp,
} from 'react-icons/io5';
import { ReactElement } from 'react';





export default function MotDG() {
    return (

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Stack spacing={4}>
                {/* <Text
              textTransform={'uppercase'}
              color={'brand.primary'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('brand.primary', 'brand.primary')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Our Story
            </Text> */}
                <Heading as='H4'
                    bgGradient="linear(to-l,  brand.secondary, brand.primary)"
                    bgClip={"text"}
                // color={useColorModeValue('brand.primary', 'brand.primary')}
                >Axelle & Arlette Hotel</Heading>
                <Text color={'gray.500'} fontSize={'lg'} align="justify">
                    Il était une fois deux sœurs qui partageaient une passion pour l'hôtellerie et la restauration et qui décidèrent de créer une entreprise. Elles ont baptisé leur entreprise 2ASoeurs. Grâce à leur expertise combinée en matière d'hébergement et de restauration, elles ont décidé de créer une expérience unique pour leurs clients.  </Text>

                <Text color={'gray.500'} fontSize={'lg'} align="justify">
                    Chez 2ASoeurs, nous savons que chaque projet est unique et nous sommes fiers de proposer des solutions sur mesure qui répondent aux besoins spécifiques de nos clients. Qu'il s'agisse de restaurer un bâtiment historique ou de créer une expérience d'hébergement luxueuse, nous nous engageons à dépasser les attentes de nos clients.
                </Text>
                <Text color={'gray.500'} fontSize={'lg'} align="justify">
                Nous sommes convaincus que l'établissement de relations à long terme avec nos clients est la clé de notre succès. C'est pourquoi nous travaillons en étroite collaboration avec eux pour comprendre leur vision et leurs objectifs et leur fournir un service personnalisé inégalé.
                </Text>
            </Stack>
            <Flex>
                <Image
                    rounded={'md'}
                    alt={'feature image'}
                    src={
                        'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                    }
                    objectFit={'cover'}
                />
            </Flex>
        </SimpleGrid>

    );
}