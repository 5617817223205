import MaterialTable from 'material-table';
import React, { useEffect } from 'react'
import * as libUi from './lib'
import { Button, Flex, Spacer, Box, useColorModeValue } from '@chakra-ui/react'

import { MdAdd } from 'react-icons/md';

function Datatable({
  data,
  columns,
  setData,
  delRow,
  onSave,
  onDelete,
  onGet,
  title,
  icon,
  setItem,
  onToggle
}) {
  const [rows, setRows] = React.useState([])
  const [dataColumns, setDataColumns] = React.useState([])
  const bg = useColorModeValue('brand.primary', 'brand.primary')
  useEffect(() => {

  }, [rows])

  useEffect(() => {
    let tab = []
    columns.map(item => {
      tab.push({
        ...item,
        cellStyle: {
          whiteSpace: 'nowrap'
        }
      })
    })
    setDataColumns(tab)
    setRows(data)
  }, [data, columns])

  // const delRowInTab = () => {
  //   if (delRow) {
  //     const dataDelete = [...data];
  //     const index = rowData.tableData.id;
  //     dataDelete.splice(index, 1);
  //     setData([...dataDelete]);
  //   }
  // }

  return (
    <>
      <Box
        minW={{ base: "sm", md: "100%" }}
        p={{ base: 2, md: 10 }}

      >
        {title &&
          <Flex pb={5}>
            <Button
              bgColor={bg}
              color="white"
              leftIcon={icon}
              w={{ base: "xs", md: "md" }}
              m={{ base: 1, md: 3 }}
            >
              {title}
            </Button>
            <Spacer />
            <Button
              onClick={() => {
                setItem({})
                onToggle()
              }}
              color={"white"}
              bgColor={bg}
              w={{ base: "xs", md: "md" }}
             
              m={{ base: 1, md: 3 }}
              leftIcon={<MdAdd />}
            >
              {"Ajouter"}
            </Button>
          </Flex>
        }


        <MaterialTable
          title=""
          columns={dataColumns}
          // data={(query)=> new Promise((resolve, reject) => {
          //    onGet(query, resolve)       
          //     })
          // }
          data={rows}
          icons={libUi.tableIcons}
          localization={libUi.french}
          onRowClick={(event, rowData) => {
            event.preventDefault()
            console.log("first", rowData)
            setItem(rowData)
            onToggle()

          }}
          options={{
            headerStyle: {
              whiteSpace: 'nowrap',
              backgroundColor: '#7F2D6F',
              color: "white",
              fontFamily: 'poppins',
              fontSize: "14px",
            },
            pageSize:10,
            pageSizeOptions:[10,20,30,50,100],
            exportButton: true
          }}
         


        />
      </Box>

    </>
  )
}

export default Datatable


