import React, { createContext, useReducer , useEffect, useState} from "react";
import axios from "axios";
import AppReducer from "./reducer";
import Service from "../services/apis/service.service"
import { useHistory } from "react-router-dom";
import WebService from "../services/apis/WebService";
const initialState = {
  dataUser: {},
  privileges:[],
  token: null,
}
const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    sessionStorage.getItem(localStorageKey) || ''
  );
 
  React.useEffect(() => {
    sessionStorage.setItem(localStorageKey, value);
  }, [value]);
 
  return [value, setValue];
};
export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [token, setToken] = useStateWithLocalStorage("token");
  const [dataUser, setDataUser] = useStateWithLocalStorage("dataUser");
  const [service, setService] = useState(new WebService(state.token))
  let history = useHistory();
  function hasRight(a1,a2){
    console.log(a1.filter(function(n) { return a2.indexOf(n) !== -1;}))
    return  (a1.filter(function(n) { return a2.indexOf(n) !== -1;})).length!==0;
}
  async function login(data) {
    try {
      await service.login(data,resp =>{
        console.log(resp)
         setToken(resp.data.access_token)
         setDataUser(JSON.stringify({user:{email: data.mail}}))
         console.log("instance====>", "in")
         setService(new WebService(resp.data.access_token) )
        dispatch({
          type: "LOGIN",
          payload: { 
            token: resp.data.access_token,
             dataUser: {user:{email: data.mail}}
            }
        });
      console.log("I reached Here ===>>> ")
       return true
      },
      error => {
        console.error("error======",error);
        return false
    })
      
    } catch (e) {
    //console.log(e);
    return false
    }
  }
  async function retrieve() {
    try {
    //console.log("retrieve====>", dataUser)
    // const getDataUser=await sessionStorage.getItem('dataUser')
    // const getToken=await sessionStorage.getItem('token')
    console.log("instance====>", "in")
    setService(new WebService(token) )
      dispatch({
        type: "RETRIEVE_TOKEN",
        payload: { token: token, dataUser: JSON.parse(dataUser)},
      });


    } catch (e) {
    //console.log(e);
    }
    // console.log('user token: ', userToken);

  }
  async function registration(data) {
    try {
      service.presubscription( {...data, linked_offre:1}, resp =>{
      //console.log(resp)
        if (resp.code===200){
          login({login:data.telephone, password: data.password}).then(response=>{
            if (resp.code !== 200) {
              throw new Error("Failed");
            }

          })
  
        }
        
      },
      error => {
       
        return  console.error(error);
        
    })
    } catch (err) {
      dispatch({
        type: "TRANSACTION_ERROR",
        payload: err,
      });
    }
  }

  async function logout() {
    sessionStorage.clear()
    dispatch({
      type: "LOGOUT",
    });

  }
  useEffect(() => {
   
  }, [token])
  
  return (
    <GlobalContext.Provider
      value={{
        ...state,
        login,
        registration,
        retrieve,
        logout,
        hasRight,
        service,
        setDataUser
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
