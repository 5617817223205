import {
    Box, Container,
    useToast, Text, Flex, Fade,
    useDisclosure,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect } from 'react'
  import * as yup from "yup";
  import { GlobalContext } from '../../context';
  import RegionService from '../../services/apis/regionservice';
  
  import CRUD from '../composants/Crud';
  
  const schema = yup.object().shape({
  
    // telephone: yup.string()
    // .required("Le numéro de téléphone obligatoire")
    // .phone("TD", true, "Le numéro de téléphone invalide"),
    code: yup.string().required("Le code est obligatoire"),
    libelle: yup.string().required("Le libelle est obligatoire"),
    description: yup.string().required("La description est obligatoire"),
    // email: yup.string().email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),
  });
  const champsInitial =
    [
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
              label: "Intitule",
              placeholder: "Entrer le libelle",
              type: "text",
              name: 'libelle',
              isRequired: true
            },
            {
              label: "Code",
              placeholder: "Entrer le code ",
              type: "text",
              name: 'code',
              isRequired: true
            },
  
          ]
      },
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
                label: "description",
                placeholder: "Entrer le code ",
                type: "textarea",
                name: 'description',
                isRequired: true
              }
  
          ]
      },
  
  
    ]
  const listeColumn = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    }
  ]
  
  const TypeChambre = ({
    getTypeChambres
  }) => {
    
    
    return (
      <>
        <CRUD
          schema={schema}
          champs={champsInitial}
          columns={listeColumn}
          hasRightSave={true}
          hasRightDelete={true}
          hasRightUpdate={true}
          path={"typechambre"}
          getData={getTypeChambres}
          title="Type de chambre"
          icon={null}
        />
  
  
  
  
      </>
    )
  }
  export default TypeChambre