import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { GlobalContext } from '../../context'
import DataAccordions from '../composants/DataAccordions'
import DataTabs from '../composants/DataTabs'
import SidebarWithHeader from '../home/sidebard'
import Privilege from './Privilege'
import Role from './Role'
import Utilisateur from './Utilisateur'


const Administration = (props) => {
  const tabAdmin= [
      {
        label: 'Gestion utilisateur',
        content: <Utilisateur/>
      },
      {
        label: 'Gestion rôle',
        content:<Role/>,
      },
      {
          label: 'Gestion privilege',
          content:<Privilege/>,
        },
    ]
 
  return (
    <SidebarWithHeader>
      

          <DataTabs data={tabAdmin}/>
      </SidebarWithHeader>
  )
}
export default Administration