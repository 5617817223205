import {
    Card,
    CardContent,
    CardHeader,
    Grid,
} from '@material-ui/core'
import React from 'react'
import Typography from "@material-ui/core/Typography";
import { Spacer, Box, Button, Flex , Center} from '@chakra-ui/react';


const CardForm = ({ cardItem, children }) => {


    return (
        <>
            <Box
                minW={{ base: "full", md: "100%", lg: "90%" }}
                p={5}

            >
                {cardItem?.libelle &&
                    <Flex
                        my={5}
                        boxShadow={'brand.primary'}
                        borderBottomWidth="4px"
                        borderLeftWidth="8px"
                        borderColor="brand.primary"
                    >
                        <Typography style={{ color: "brand.primary" }} className="filterTitle" />
                        {/* <Box> {cardItem.icon} </Box><Spacer /><Box>{cardItem.libelle}</Box> */}
                        <Button
                            // bgColor={"#F5F6F7"}
                            color="brand.primary"
                            leftIcon={cardItem.icon}
                            w="full"
                        >
                            {cardItem.libelle}
                        </Button>

                    </Flex>

                }
                {/* <Grid spacing={2} container> */}
                    <Center py={5} justify='right'>
                        <Box>
                        {children}
                        </Box>
                    </Center>
                {/* </Grid> */}
            </Box>

        </>
    )
}

export default CardForm