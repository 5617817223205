import {
    Avatar,
    Box,
    Button,
    Container,
    Heading,
    HStack,
    Image,
    SimpleGrid,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    Divider
} from '@chakra-ui/react'
import * as React from 'react'

export const Conteneur = ({ titre, children }) => {
    const isMobile = useBreakpointValue({ base: true, md: false })
    return (
        <Box
            pb={6}

        >
            <Container
                boxShadow={useColorModeValue('brand.primary', 'brand.primary')}
                borderTopWidth="4px"
                // borderTopWidth="4px"
                borderColor="brand.primary"
                minW={{
                    base: '100%',
                    md: '80%',
                }}
                py={{ base: '5', md: '5' }}
                my={{ base: '5', md: '10' }}
            >

                <HStack pb={5}>
                    <Divider
                    />
                    <Button flexShrink={0} variant="secondary" >
                        <Heading as='H1'
                            bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                            bgClip='text'
                            fontWeight="bold"
                            
                        >
                            {titre.toUpperCase()}
                        </Heading>
                    </Button>
                    <Divider />
                </HStack>

                {children}
            </Container>
        </Box>
    )
}
