import {
    Box, Container,
    useToast, Text, Flex, Fade,
    useDisclosure,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect, useContext } from 'react'
import { Util } from 'reactstrap';
  import * as yup from "yup";
  import "yup-phone";
import { GlobalContext } from '../../context';
import CRUD from '../composants/Crud';
  
  const schema = yup.object().shape({
  
    telephone: yup.string()
    .required("Le numéro de téléphone obligatoire"),
    nom: yup.string().required("Le nom est obligatoire"),
    prenom: yup.string().required("Le prénom est obligatoire"),
    matricule: yup.string().required("Le matricule est obligatoire").length(7,"7 caractères"),
    email: yup.string().email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),
    typeService: yup.string().required("Le type service est obligatoire"),
    service: yup.string().required("Le service est obligatoire"),
    fonction: yup.string().required("La fonction est obligatoire"),
    role: yup.string().required("Le rôle est obligatoire"),
    civilite: yup.string().required("La civilité est obligatoire"),
    chefService: yup.string("Le status est obligatoire").required("Le status est obligatoire"),
     
  });
   const champsInitial =
  [
    {libelle: null,
      icon: null,
      liste:
    [
      {
        label: "Matricule",
        placeholder: "Entrer le matricule",
        type: "text",
        name: 'matricule',
        isRequired: true
      },
      {
        label: "Nom",
        placeholder: "Entrer le nom ",
        type: "text",
        name: 'nom',
        isRequired: true
      },
      {
        label: "Prénom",
        placeholder: "Entrer le prénom ",
        type: "text",
        name: 'prenom',
        isRequired: true
      },
      {
        label: "Téléphone",
        placeholder: "Entrer le téléphone ",
        type: "text",
        name: 'telephone',
        isRequired: true
      },
      {
        label: "Email",
        placeholder: "Entrer le mail ",
        type: "text",
        name: 'email',
        isRequired: true
      },
      {
        label: "Email 2",
        placeholder: "Entrer le deuxième mail  ",
        type: "text",
        name: 'email1',
        isRequired: false 
      },
      {
        label: "Type service",
        placeholder: "Selectionner le type Service",
        type: "select",
        name: 'typeService',
        liste: [
          {
            key: "item1",
            value: "Item 1"
          },
          {
            key: "item2",
            value: "Item 2"
          }
  
        ],
        isRequired: true
      },
      {
        label: "Service",
        placeholder: "Selectionner le service",
        type: "select",
        name: 'service',
        liste: [
          {
            key: "item1",
            value: "Item 1"
          },
          {
            key: "item2",
            value: "Item 2"
          }
  
        ],
        isRequired: true
      },
      {
        label: "Fonction",
        placeholder: "Selectionner la fonction",
        type: "select",
        name: 'fonction',
        liste: [
          {
            key: "item1",
            value: "Item 1"
          },
          {
            key: "item2",
            value: "Item 2"
          }
  
        ],
        isRequired: true
      },
      {
        label: "Civilté",
        placeholder: "Selectionner la civilité",
        type: "select",
        name: 'civilite',
        liste: [
          {
            key: "item1",
            value: "Item 1"
          },
          {
            key: "item2",
            value: "Item 2"
          }
  
        ],
        isRequired: true
      },
      {
        label: "Chef de service",
        placeholder: "Selectionner le status",
        type: "select",
        name: 'chefService',
        liste: [
          {
            key: false,
            value: "Non"
          },
          {
            key: true,
            value: "Oui"
          }
  
        ],
        isRequired: true
      },
      {
        label: "Role",
        placeholder: "Selectionner le role",
        type: "select",
        name: 'role',
        liste: [
          {
            key: "item1",
            value: "Item 1"
          },
          {
            key: "item2",
            value: "Item 2"
          }
  
        ],
        isRequired: true
      },
  
    ]},
    
  
  ]
  const listeColumn = [
    {
      title: 'Matricule',
      field: 'matricule',
    },
    {
      title: 'Nom',
      field: 'nom',
    },
    {
      title: 'Prénom',
      field: 'prenom',
    },
    {
      title: 'Service',
      field: 'service',
    },
    {
      title: 'Fonction',
      field: 'fonction',
    }, {
      title: 'Rôle',
      field: 'role',
    },
  ]

  const Utilisateur = () => {
    const context = useContext(GlobalContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = React.useState([{
      matricule: "1234567A",
      nom: "John",
      prenom: "Doe",
      service: "item1",
      fonction: "Comptable",
      role:"item2"
  
    }])
    const { service, token, dataUser } = context
    const toast = useToast()
    const [isUpdate, setisUpdate] = React.useState(false)
  
    const onSave = (data, setData, isUpdate) => {
  
      if (!isUpdate) {
        // natureRecetteService.addPartie({partie: data}, (resp) => {
        //     natureRecetteService.getPartie((resp) => {
        //         setData(resp.data)
        //         setParties(resp.data)
        //     },
        //         (error) => {
        //             console.error(error);
        //         })
        // },
        //     (error) => {
        //         console.error(error);
  
        //     })
  
      }
      else {
        console.log("dattaaa=>", data)
        // natureRecetteService.updatePartie({updatePartie: data}, (resp) => {
        //     natureRecetteService.getPartie((resp) => {
        //         setData(resp.data)
        //         setParties(resp.data)
        //     },
        //         (error) => {
        //             console.error(error);
        //         })
        // },
        //     (error) => {
        //         console.error(error);
  
        //     })
      }
    }
  
    const onDelete = (data, setData) => {
      // const datas = { externalId:data.meta.uid};
      // console.log("datas",datas)
      // natureRecetteService.deletePartie(datas, (resp) => {
      //         natureRecetteService.getPartie((resp) => {
      //                 setData(resp.data)
      //                 setParties(resp.data)
      //             },
      //             (error) => {
      //                 console.error(error);
      //             })
      //     },
      //     (error) => {
      //         console.error(error);
  
      // })
  
    }
  
  
    const onGet = (setData) => {
      setData(data)
      // natureRecetteService.getPartie((resp) => {
  
      //     setData(resp.data)
      //     setParties(resp.data)
      // },
      //     (error) => {
      //         console.error(error);
      //     })
    }
    const updatePartie = (oldPartie, setData) => {
      // natureRecetteService.updatePartie(oldPartie ,(resp) => {
      //         //console.log("datass==>",resp.data)
      //         setData(resp.data)
      //         setParties(resp.data)
      //     },
      //     (error) => {
      //         console.error(error);
      //     })
  
    }
    useEffect(() => {
  
    }, [data])
    return (
      <>
  
        
              <CRUD
                schema={schema}
                champs={champsInitial}
                columns={listeColumn}
                onSave={onSave}
                onDelete={onDelete}
                onGet={onGet}
                hasRightSave={true}
                hasRightDelete={true}
                hasRightUpdate={true}
                title="Utilisateur"
                icon={null}
              />
  
      </>
    )
  }
  export default Utilisateur
  