import { FormErrorMessage, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const InputField = React.forwardRef((props, ref) => {
  const { label,errors, reference, name, ...rest } = props
  return (
    <FormControl position="relative" isInvalid={errors} size="lg">
      <FormLabel
        _focus={{
          color: mode('brand.primary', 'brand.primary'),
        }}
        fontWeight="semibold"
        position="absolute"
        color={mode('gray.600', 'white')}
        top="-3"
        insetStart="2"
        bg={{
          base: mode('white', 'gray.800'),
          md: mode('white', 'gray.700'),
        }}
        zIndex={2}
        px="2"
        fontSize={{base:"sm", md:"md"}}
      >
        {label}
      </FormLabel>
      <Input ref={reference} size="md" focusBorderColor="brand.secondary" fontSize={{base:"sm", md:"md"}} {...rest} name={name}/>
      <FormErrorMessage>
        {errors?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
InputField.displayName = 'InputField'
