import { FormErrorMessage, Checkbox, CheckboxGroup, HStack, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const CheckBoxField = React.forwardRef((props, ref) => {
  const { liste, label, Controller, control, direction, errors, reference, name, ...rest } = props
  return (
    <FormControl position="relative" isInvalid={errors}>
      <FormLabel

      >
        {label}
      </FormLabel>
      <Controller
        as={
          <CheckboxGroup {...reference(name)} isInline spacing={4}
            render={({ field }) =>
              <HStack spacing={4} direction={"row" || direction}>
                {liste.map((item) => {
                  return (
                    <Checkbox
                      value={item.key}
                      {...field}
                    > {item.value}</Checkbox>
                  )
                })
                }
              </HStack>
            }
          >
          </CheckboxGroup>
        }
        name={name}
        control={control}
      />
      <FormErrorMessage>
        {errors?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
CheckBoxField.displayName = 'CheckBoxField'
