import { FormErrorMessage, Select, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import * as React from 'react'

export const MultiSelectField = React.forwardRef((props, ref) => {
  const { liste, placeholder, label, errors, reference, name, ...rest } = props
  const [pickerItems, setPickerItems] = React.useState(liste);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  return (
    <FormControl position="relative" isInvalid={errors[name]}>
      <CUIAutoComplete
      label={label}
        placeholder={placeholder}
        onCreateItem={handleCreateItem}
        items={pickerItems}
        selectedItems={selectedItems}
        onSelectedItemsChange={(changes) =>
          handleSelectedItemsChange(changes.selectedItems)
        }
        ref={reference}
        size="md" 
        focusBorderColor="brand.secondary" 
        fontSize="md" {...rest} 
        name={name}
        disableCreateItem={true}
        />
        
      <FormErrorMessage>
        {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
MultiSelectField.displayName = 'MultiSelectField'
