import {
    Box, Container,
    useToast, Text, Flex, Fade,
    useDisclosure,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect } from 'react'
  import * as yup from "yup";
  import { GlobalContext } from '../../context';
  import RegionService from '../../services/apis/regionservice';
  
  import CRUD from '../composants/Crud';
  
  const schema = yup.object().shape({
  
    // telephone: yup.string()
    // .required("Le numéro de téléphone obligatoire")
    // .phone("TD", true, "Le numéro de téléphone invalide"),
    code: yup.string().required("Le code est obligatoire"),
    libelle: yup.string().required("Le libelle est obligatoire"),
    heure_in: yup.string().required("L'heure entree est obligatoire"),
    duree: yup.string().required("La duree est obligatoire"),
    // email: yup.string().email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),
  });
  const champsInitial =
    [
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
              label: "Intitule",
              placeholder: "Entrer le libelle",
              type: "text",
              name: 'libelle',
              isRequired: true
            },
            {
              label: "Code",
              placeholder: "Entrer le code ",
              type: "text",
              name: 'code',
              isRequired: true
            },
           
  
          ]
      },
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
                label: "Heure entrée",
                placeholder: "Entrer l'heure entrée ",
                type: "time",
                name: 'heure_in',
                isRequired: true,
                defaultValue:"00:00"
              },
              {
                label: "Heure sortie",
                placeholder: "Entrer l'heure sortie ",
                type: "time",
                name: 'heure_out',
                defaultValue:"00:00",
                isRequired: false
              },
              {
                label: "Duree",
                placeholder: "Entrer la duree",
                type: "number",
                name: 'duree',
                params:{min:0, max:23},
                isRequired: false
              },
          ]
      },
  
  
    ]
  const listeColumn = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    },
    {
        title: 'Heure entrée',
        field: 'heure_in',
      },
      {
        title: 'Heure sortie',
        field: 'heure_out',
      },
      {
        title: 'Duree(Heure)',
        field: 'duree',
      }
  ]
  
  const TypeReservation = ({
    getTypeReservations
  }) => {
    
    
    return (
      <>
        <CRUD
          schema={schema}
          champs={champsInitial}
          columns={listeColumn}
          hasRightSave={true}
          hasRightDelete={true}
          hasRightUpdate={true}
          path={"typereservation"}
          getData={getTypeReservations}
          title="Type de Reservation"
          icon={null}
        />
  
  
  
  
      </>
    )
  }
  export default TypeReservation