import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  Container,
  Heading,
  Link,
  Image,
  Box,
  SimpleGrid,
  Spacer,
  useDisclosure

} from '@chakra-ui/react';
import { FiCalendar, FiSend } from 'react-icons/fi'
import { FarmerChambre } from './FarmerChambre'
import Logo from '../media/images/logo2.png'
import Animate from "../media/images/animate.svg"
import ReservationForm from '../GestionReservation/ReservationForm';
export const Banner = () => {
  return (
    <Flex
      as="section"
      w={'full'}
      h={'780px'}
      backgroundImage={
        "url(https://images.unsplash.com/photo-1444201983204-c43cbd584d93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&dl=markus-spiske-g5ZIXjzRGds-unsplash.jpg&w=1920)"
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}

    >
      <Box
        w={'full'}
        bgImage={Animate}
        bgRepeat="no-repeat"
        bgSize="100% 100%"
        bgPosition={"relative"}
      // opacity="0.7"
      >

        <VStack
          w={'full'}
          // justify={'center'}
          px={useBreakpointValue({ base: 4, md: 8 })}
        // bgGradient={useColorModeValue('linear(to-t, brand.primary, transparent)', 'linear(to-t, brand.primary, transparent)')}

        >

          <Spacer />
          <Stack minW={'7xl'} direction={{ base: "column", md: "row" }} align={'center'} spacing={6}>
            <Stack minW={'4xl'} align={'center'} spacing={1}>
              <Image
                w={{ base: '64px', md: '500px' }}
                h={{ base: '64px', md: 'auto' }}
                // objectFit='cover'
                src={Logo}
                alt='2Asoeurs logo'
                // mx={2}
                display={{ base: 'none', md: 'block' }}
              />
              <Text
                bgGradient="linear(to-l,brand.secondary, brand.primary)"
                bgClip='text'
                fontWeight={700}
                lineHeight={1.2}
                fontSize={useBreakpointValue({ base: 'lg', md: '4xl' })}
                fontAlign="center"
                py={-2}
              >
                Hebergement & Restauration-Bar
              </Text>

            </Stack>
            <Spacer />
            <Container maxW="full" py={10} px={{ base: 5, md: 8 }}
            >
              <FramerChambre />
            </Container>
          </Stack>
          <Spacer />

          <Box
            display={{ base: 'none', md: 'none' }}
            position="absolute"
            zIndex={2}
            w="full"
            bottom="0"
            my="20"
            bg='gray.800'
            // opacity="0.9"
          >
            <Box minW={{ base: 'xl', md: '7xl' }} mx="auto">
              <SimpleGrid columns={{ base: 1, md: 4 }} color="brand.ligthGrey">
                <Box textAlign="center" color="white">
                  <Text
                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                    bgClip='text'
                    fontSize="xl" fontWeight={"bold"}>Chambre Simple</Text>
                  <Text fontSize="2xl">12</Text>
                </Box>
                <Box textAlign="center" color="white">
                  <Text
                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                    bgClip='text'
                    fontSize="xl" fontWeight={"bold"}>Chambre VIP</Text>
                  <Text fontSize="2xl">10</Text>
                </Box>
                <Box textAlign="center" color="white">
                  <Text
                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                    bgClip='text'
                    fontSize="xl" fontWeight={"bold"}>Suite</Text>
                  <Text fontSize="2xl">6</Text>
                </Box>
                <Box textAlign="center" color="white">
                  <Text
                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                    bgClip='text'
                    fontSize="xl"
                    fontWeight={"bold"}>Appartement</Text>
                  <Text fontSize="2xl">4</Text>
                </Box>
              </SimpleGrid>
            </Box>
          </Box>
        </VStack>

      </Box>
    </Flex>

  );
}

export const FramerChambre = () => {
  const { isOpen, onClose, onToggle } = useDisclosure()

  return (
    <>
      <Container
        maxW="2xl"
        maxH="2xl"
        p={{ base: 5, md: 5 }}>
        <Stack spacing={4} minW={{ base: 'full', md: '100%' }} margin="0 auto">

          <Box pos="relative">
            <Box
              pos="absolute"
              top="-7px"
              right="-7px"
              bottom="-7px"
              left="-7px"
              rounded="lg"
              bgGradient="linear(to-l, brand.primary,brand.secondary)"
              transform="rotate(-3deg)"
              opacity={0.8}
            ></Box>
            <VStack
              as="form"
              pos="relative"
              spacing={8}
              p={6}
            // rounded="lg"
            // boxShadow="lg"
            >
              <Stack align="center" spacing={2}>
                <Heading color="white" fontSize={{ base: 'md', sm: '3xl' }}>Chambre confort</Heading>
              </Stack>
              <Flex>
                <Image
                  rounded={'md'}
                  alt={'feature image'}
                  src={
                    'https://images.unsplash.com/photo-1633948393301-d43e3ec0e5cd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&dl=laura-adai-j67CwQzRLPg-unsplash.jpg&w=1920'
                  }
                  objectFit={'cover'}
                />
              </Flex>
              <Stack direction={'row'}>
                <Box
                  size={{ base: "sm", md: "2xl" }}
                >
                  <Button
                    w="full"
                    leftIcon={<FiCalendar />}
                    textTransform="uppercase"
                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"

                    color={"white"}
                  onClick={onToggle}
                  >
                    <Link to="/donateur">Faire une reservation</Link>
                  </Button>

                </Box>
              </Stack>
              {/* <Button
                bg="blue.400"
                color="white"
                _hover={{
                  bg: 'blue.500'
                }}
                rounded="md"
                w="100%"
              >
                Send magic link
              </Button> */}
            </VStack>
          </Box>
        </Stack>
      </Container>
      <ReservationForm
        isOpen={isOpen}
        onToggle={onToggle}
      />
    </>
  )
}

