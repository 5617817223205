import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import * as yup from "yup";
import "yup-phone";
import Formulaire from '../composants/Crud/Form';
import { GlobalContext } from '../../context';


function ReservationForm({ onClose, isOpen, onToggle }) {
    const [item, setItem] = React.useState({})
    const context = React.useContext(GlobalContext)
    const apiService = context.service
    const { service, token, dataUser, logout } = context
    const schema = yup.object().shape({
        nom: yup.string().required("Le nom  est obligatoire"),
        mail: yup.string().email().required("Le mail est obligatoire"),
        telephone: yup.string().phone().required("Le telephone est obligatoire"),

    });
    const champsInitial =
        [
            {
                libelle: "Client informations",
                icon: null,
                liste:
                    [
                        {
                            label: "Nom et Prenom",
                            placeholder: "Entrer le nom complet",
                            type: "text",
                            name: 'nom',
                            isRequired: true
                        },
                        {
                            label: "Telephone",
                            placeholder: "Entrer le libelle",
                            type: "text",
                            name: 'telephone',
                            isRequired: true
                        },
                        {
                            label: "Email",
                            placeholder: "Entrer le code ",
                            type: "text",
                            name: 'mail',
                            isRequired: true
                        },
                        // {
                        //     label: "Message",
                        //     placeholder: "Selectionner la région",
                        //     type: "textarea",
                        //     name: 'description',
                        //     isRequired: false
                        // }

                    ]
            },
        ]
    const onSave = async (data, setData, isUpdate = false) => {

        await apiService.create(token, "reservation", data, (resp) => {



        },
            (error) => {
                if (error?.response?.status === 401) {
                    logout()

                }
                console.error(error);

            })


    }
    return (
        <>
            <Drawer onClose={onClose} isOpen={isOpen} size={"lg"}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    {/* <DrawerHeader>{`drawer contents`}</DrawerHeader> */}
                    <DrawerBody>
                        <Formulaire
                            schema={schema}
                            champsInitial={champsInitial}
                            columns={2}
                            isOpen={true}
                            onToggle={onToggle}
                            onSave={onSave}
                            item={item}
                            setRefresh={null}
                            // buttonLabel={buttonLabel}
                            title={"Reservation "}
                            onDelete={null}
                            setData={null}
                            setItem={setItem}
                            updateChamps={champsInitial}
                        // icon={<FaWpforms />}

                        />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ReservationForm