export default (state, action) => {
    switch (action.type) {
      case "CREATE":
        return  {
          ...state,
          loginRedirect: action.payload.loginRedirect,
        };
        case "LOGIN":
        return {
          ...state,
          dataUser: action.payload.dataUser,
          token: action.payload.token,
        };
        case "RETRIEVE_TOKEN":
        return {
          ...state,
          dataUser: action.payload.dataUser,
          token: action.payload.token,
  
        };
        case "LOGOUT":
        return {
        };
      default:
        return state;
    }
  };