import { FormErrorMessage, Radio, RadioGroup, HStack, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const RadioField = React.forwardRef((props, ref) => {
  const { liste, isRequired, label, Controller, control, errors, reference, name, ...rest } = props
  return (
    <FormControl position="relative" isInvalid={errors[name]}>

      <Controller
        as={
          <RadioGroup isInline 
           >
            <HStack spacing={4} direction="row">
              <FormLabel

              >
                {!isRequired ? label : `${label} *`}
              </FormLabel>
              {liste.map((item) => {
                return (
                  <Radio
                  name={name} ref={reference} 
                    value={item.key} >{item.value}</Radio>
                )
              })
              }
            </HStack>
          </RadioGroup>
        }
        name={name}
        control={control}
      />
      <FormErrorMessage>
        {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
RadioField.displayName = 'RadioField'
