import {
  Box, Container,
  useToast, Text, Flex, Fade,
  useDisclosure,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect } from 'react'
import * as yup from "yup";


import CRUD from '../composants/Crud';

const schema = yup.object().shape({

  // telephone: yup.string()
  // .required("Le numéro de téléphone obligatoire")
  // .phone("TD", true, "Le numéro de téléphone invalide"),
  code: yup.string().required("Le code est obligatoire"),
  libelle: yup.string().required("Le libelle est obligatoire"),
  date_cloture: yup.string().required("L'heure entree est obligatoire"),
  tache: yup.string().required("La duree est obligatoire"),
  qualite: yup.string().required("La duree est obligatoire"),
  qualification: yup.string().required("La duree est obligatoire"),
  // email: yup.string().email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),
});
const champsInitial =
  [
    {
      libelle: null,
      icon: null,
      liste:
        [
          {
            label: "Intitule du poste",
            placeholder: "Entrer le libelle",
            type: "text",
            name: 'libelle',
            isRequired: true
          },
          {
            label: "Code",
            placeholder: "Entrer le code ",
            type: "text",
            name: 'code',
            isRequired: true
          },
          {
            label: "Date de cloture ",
            placeholder: "Entrer le date de cloture",
            type: "date",
            name: 'date_cloture',
            isRequired: true
          },
          {
            label: "Taches",
            placeholder: "Entrer le code ",
            type: "textarea",
            name: 'tache',
            isRequired: true
          },
          {
            label: "Principales qualités",
            placeholder: "Entrer le code ",
            type: "textarea",
            name: 'qualite',
            isRequired: true
          },
          {
            label: "Expériences et qualification requises",
            placeholder: "Entrer le code ",
            type: "textarea",
            name: 'qualification',
            isRequired: true
          }
         

        ]
      }


  ]
const listeColumn = [
  {
    title: 'Code',
    field: 'code',
  },
  {
    title: 'Poste',
    field: 'libelle',
  },
    {
      title: 'Date Cloture',
      field: 'date_cloture',
    }
]

const Poste = ({
  getPostes
}) => {
  
  
  return (
    <>
      <CRUD
        schema={schema}
        champs={champsInitial}
        columns={listeColumn}
        hasRightSave={true}
        hasRightDelete={true}
        hasRightUpdate={true}
        path={"poste"}
        getData={getPostes}
        title="Poste"
        icon={null}
      />




    </>
  )
}
export default Poste