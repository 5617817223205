import APIClient from '../APIClient';
const PATH_REGION="regions"
const PATH_LOGIN="login"
const PATH_IMAGE = 'uploadImages';
const PATH_FILE = 'uploadFiles';
const PATH_DEPARTEMENT="departements"
export default class WebService {

    constructor(token=null, logout) {
        this.client = APIClient.instance(token, logout);
    }
    login(data, successCallback, errorCallback) {
        this.client.post(null, PATH_LOGIN, data, successCallback, errorCallback);
    }
    getAll(token, path, data, successCallback, errorCallback) {
        this.client.get(token, path, data, successCallback, errorCallback);
    }
    create(token,path, data, successCallback, errorCallback) {
        this.client.post(token, path, data, successCallback, errorCallback);
    }

    update(token, path,externalId,data, successCallback, errorCallback) {
        this.client.put(token, `${path}/${externalId}`, data, successCallback, errorCallback);
    }
    get(token, path,externalId, successCallback, errorCallback) {
        this.client.get(token, `${path}/${externalId}`, successCallback, errorCallback);
    }

    delete(token,path, externalId, successCallback, errorCallback) {
        this.client.delete(token, `${path}/${externalId}`, successCallback, errorCallback);
    }
    getAllDepartement(data, successCallback, errorCallback) {
        this.client.get(PATH_DEPARTEMENT, data, successCallback, errorCallback);
    }
    createDepartement(data, successCallback, errorCallback) {
        this.client.post(PATH_DEPARTEMENT, data, successCallback, errorCallback);
    }

    updateDepartement(externalId,data, successCallback, errorCallback) {
        this.client.put(`${PATH_DEPARTEMENT}/${externalId}`, data, successCallback, errorCallback);
    }

    deleteDepartement(externalId, successCallback, errorCallback) {
        this.client.delete(`${PATH_DEPARTEMENT}/${externalId}`, successCallback, errorCallback);
    }
    uploadImage(data, successCallback, errorCallback) {
        this.client.postFile(PATH_IMAGE, data, successCallback, errorCallback);
    }
    uploadFile(data, successCallback, errorCallback) {
        this.client.postFile(PATH_FILE, data, successCallback, errorCallback);
    }


    deleteImage(externalId, successCallback, errorCallback) {
        this.client.delete(`${PATH_IMAGE}/${externalId}`, successCallback, errorCallback);
    }
    deleteFile(externalId, successCallback, errorCallback) {
        this.client.delete(`${PATH_FILE}/${externalId}`, successCallback, errorCallback);
    }
    
}