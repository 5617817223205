import * as React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Field } from './Field';
import { Button, Stack, Divider, Heading, HStack, GridItem, Container, ButtonGroup, useColorModeValue, Wrap, WrapItem, Center, Spacer, Box, Flex, Text } from '@chakra-ui/react'
import {
  Typography,
}
  from '@material-ui/core';
import CardForm from './Field/CardForm';
import { FiSave } from 'react-icons/fi';
import { BiSave } from 'react-icons/bi';
import { MdDeleteForever, MdKeyboardBackspace } from 'react-icons/md';



const Formulaire = ({
  champsInitial,
  schema,
  item,
  setItem,
  onDelete,
  onSave,
  title,
  icon,
  setData,
  onToggle,
  updateChamps
}) => {
  const { register, handleSubmit, getValues, reset, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(schema)


  });
  const [isUpdate, setisUpdate] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [unFilterChamps, setunFilterChamps] = React.useState(updateChamps || [])
  const [isDelete, setDelete] = React.useState(false)
  const [isAnnul, setannul] = React.useState(false)
  const bg = useColorModeValue('brand.primary', 'brand.primary')
  const onSubmit = (data) => {

    if (isUpdate && isDelete && !isAnnul) {
      console.log("data", values)
      onDelete({ ...data, ...values }, setData);

    } else {
      const dataout = { ...values, ...data }
      const newObj = {};
      for (const [key, value] of Object.entries(dataout)) {
        if (!unFilterChamps.includes(key)) {
          newObj[key] = value;
        }
      }
      onSave(newObj, setData, isUpdate)
    }
    setDelete(false);
    setValues({});
    reset({});
    setannul(false);
    setItem({});
    setisUpdate(false);
    onToggle()
    // history.push('/')

  };
  function isEmptyObject(value) {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }
  React.useEffect(() => {
    reset(values)

  }, [values])
  React.useEffect(() => {

    const { linkedMeta, tableData, ...restItem } = item


    // console.log("filteredItem", newObj)

    setValues({ ...item })
    if (!isEmptyObject(item)) {
      setisUpdate(true)
    }

  }, [item])
  React.useEffect(() => {

  }, [])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box
        minW={{ base: "sm", md: "100%" }}
        px={{ base: 2, md: "10%" }}

        shadow="lg"

      >
        {title &&
          <HStack pb={2}>
            <Divider
            />
            <Button flexShrink={0} variant="secondary" >
              <Heading as='H1'
                bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                bgClip='text'
                fontWeight="bold"

              >
                {title.toUpperCase()}
              </Heading>
            </Button>
            <Divider />
          </HStack>}
        {/* <Center > */}

        {
          champsInitial.map(listeItem => {
            return (
              <CardForm
                cardItem={listeItem}
                {...listeItem} >
                <Wrap spacing='10' justify="center">

                  {
                    listeItem?.liste?.map((champs, index) => {
                      return (
                        <>
                          <WrapItem size={champs?.type !== "textarea" ? { base: "full", md: "md" } : { base: "full", md: "100%", lg: "100%" }} key={index}>
                            {/* <Center > */}

                            {champs?.icon}
                            <Typography color={"secondary"} className="form-label" style={{ fontWeight: "bold", marginLeft: "5px" }} variant="button" gutterBottom>
                              {champs?.libelle}
                            </Typography>


                            <Field
                              {...champs}
                              reference={register}
                              Controller={Controller}
                              control={control}
                              errors={errors}
                              values={values}
                              getValues={getValues}
                            />
                            {/* </Center> */}


                          </WrapItem>
                        </>




                      )
                    })
                  }

                </Wrap>



              </CardForm>
            )
          })
        }
        {/* </Center> */}
        <Stack direction={{ md: 'row', base: 'column' }} py={5} spacing={5}>
          <Stack direction={{ md: 'row', base: 'column' }} py={5} spacing={5}>

            {/* {isUpdate &&
              <Button
                onClick={() => {
                  onDelete(item, setData);
                  onToggle()
                }}
                //type="submit"
                bgColor={bg}
                color="white"
                w={{ base: "full", md: "auto" }}
                // m={{ base: 1, md: 3 }}

                leftIcon={<MdDeleteForever />}
              >
                {"Supprimer"}
              </Button>} */}
          </Stack>
          {isUpdate &&
            <Button
              onClick={() => {
                onDelete(item, setData);
                onToggle()
              }}
              //type="submit"
              bgColor={bg}
              color="white"
              w={{ base: "full", md: "auto" }}
              // m={{ base: 1, md: 3 }}

              leftIcon={<MdDeleteForever />}
            >
              {"Supprimer"}
            </Button>}
          <Button
            bgColor={bg}
            type="submit"
            color="white"
            leftIcon={<BiSave />}
            // mx={5}
            w={{ base: "full", md: "auto" }}
            m={{ base: 1, md: 3 }}
          >
            {!isUpdate ? "Enregistrer" : "Mettre à jour"}
          </Button>

          <Spacer />
          <Button
            onClick={() => { setannul(true); onToggle() }}
            //type="submit"
            bgColor={bg}
            color="white"
            w={{ base: "full", md: "auto" }}
            // m={{ base: 1, md: 3 }}
            leftIcon={<MdKeyboardBackspace />}
          >
            {"Retour"}
          </Button>

        </Stack>
      </Box>


    </form>
  )
}

export default Formulaire