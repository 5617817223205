import { FormErrorMessage, FormControl,FormHelperText, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const InputField = React.forwardRef((props, ref) => {
  const { label,errors, reference, helpText, isRequired, name, ...rest } = props
  return (
    <FormControl  isInvalid={errors[name]}  size={{base:"xs", md:"lg"}}>
      <FormLabel
        _focus={{
          color: mode('brand.primary', 'brand.primary'),
        }}
        fontWeight="semibold"
        position="absolute"
        color={mode('gray.600', 'white')}
        top="-7"
        // insetStart="2"
        // bg={{
        //   base: mode('white', 'gray.800'),
        //   md: mode('white', 'gray.700'),
        // }}
         zIndex={2}
        px="2"
        fontSize={{base:"sm", md:"md"}}
      >
        {!isRequired? label: `${label} *`}
      </FormLabel>
      <Input ref={reference} 
       minW={{base:"xs", md:"lg"}}
      focusBorderColor="brand.secondary" 
      fontSize={{base:"xs", md:"md"}} {...rest} name={name}/>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
      <FormErrorMessage>
        {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
InputField.displayName = 'InputField'
