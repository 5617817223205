import {
    Box, Container,
    useToast, Text, Flex, Fade,
    useDisclosure,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect, useContext } from 'react'
  import * as yup from "yup";
import { GlobalContext } from '../../context';
import WebService from '../../services/apis/WebService';
import CRUD from '../composants/Crud';


  const Ville = ({
    departementsIn,
    getVilles
  }) => {
    const [departements, setDepartements] = React.useState([])
    const [objetdepartements, setObjetDepartements] = React.useState([])
  const villeService=new WebService("villes")
  const schema = yup.object().shape({
    code: yup.string().required("Le code est obligatoire"),
    libelle: yup.string().required("Le libelle est obligatoire"),
    departement_id: yup.string().required("Le departement est obligatoire"),
   
  });
  const champsInitial =
    [
      {
        libelle: null,
        icon: null,
        liste:
          [
            {
              label: "Libelle",
              placeholder: "Entrer le libelle",
              type: "text",
              name: 'libelle',
              isRequired: true
            },
            {
              label: "Code",
              placeholder: "Entrer le code ",
              type: "text",
              name: 'code',
              isRequired: true
            },
            {
              label: "Departement",
              placeholder: "Selectionner la departement",
              type: "select",
              name: 'departement_id',
              liste:departements,
              isRequired: true
            }

          ]
      },


    ]
  const listeColumn = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    },
    {
      title: 'Departement',
      field: 'departement_id',
      lookup: objetdepartements
    }
  ]
  
  useEffect(() => {
    setDepartements(departementsIn.map(item => ({
      key: item.id,
      value: item.libelle
    })))
    let obj={}
    departements.map(item=>
      {
        const id={}
        id[`${item.id}`]=item.libelle
        obj=Object.assign(obj, id)
      
      
    })    
    setObjetDepartements(obj)

  }, [departementsIn])
  useEffect(() => {
    setDepartements(departementsIn.map(item => ({
      key: item.id,
      value: item.libelle
    })))
    let obj={}
    departements.map(item=>
      {
        const id={}
        id[`${item.id}`]=item.libelle
        obj=Object.assign(obj, id)
      
      
    })    
    setObjetDepartements(obj)

  }, [])
    return (
      <>

  
              <CRUD
                schema={schema}
                champs={champsInitial}
                columns={listeColumn}
                hasRightSave={true}
                hasRightDelete={true}
                hasRightUpdate={true}
                title="Ville"
                path="villes"
                getData={getVilles}
                icon={null}
              />
           
  
      </>
    )
  }
  export default Ville
  