import React, { useContext, useState, useEffect, } from 'react'
import { GlobalContext } from '../../context'
import DataAccordions from '../composants/DataAccordions'
import { Box, Spacer } from '@chakra-ui/react'
import { Conteneur } from '../accueil/Conteneur'
import { Banner } from './Banniere'
import Header from "./header"
import { Parser } from "html-to-react";
import CardPoste from "./cardPoste"
import CAMEF from './Camef'
import Contact from './Contact.js'
import Footer from './Footer.js'
import { Animation } from '../composants/Animation'

function Opportunite() {
    const context = React.useContext(GlobalContext)
    const apiService = context.service
    const { service, token, dataUser, logout } = context
    const [postes, setPostes] = useState([])
    const [tabPostes, setTabPostes] = useState([])
    const htmlParser = new Parser();
    useEffect(() => {
        apiService.getAll(token, "poste", (resp) => {
            const data = resp.data.data
            setPostes(data)
            setTabPostes(data.map(item => {
                return {
                    "label": `${item?.libelle} - Date de clôture ${item?.date_cloture}`,
                    "content": <CardPoste poste={item} postes={data} />
                }
            }))
        },
            (error) => {
                if (error?.response?.status === 401) {
                    logout()

                }
                console.error("error====>", error);
            })
    }, [])

    return (
        <>
            <Box as="section">
                <Header />
                <Banner postes={postes} />
                
                <Animation>
                <Conteneur titre={"Offres d'emploi"}>
                    <DataAccordions
                        data={tabPostes}
                    />
                </Conteneur>
                </Animation>
                <Animation>
                <Conteneur
                    titre={"Presentation"}
                >
                    <CAMEF />
                </Conteneur>
                </Animation>
                <Animation>
                <Conteneur
                    titre={"Nous contacter"}
                >
                    <Contact />
                </Conteneur>
                </Animation>
                <Footer/>
            </Box>

        </>
    )
}

export default Opportunite