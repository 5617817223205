
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useState, useEffect } from 'react'
  import { GlobalContext } from '../../context'
  import DataAccordions from '../composants/DataAccordions'
  import DataTabs from '../composants/DataTabs'
  import SidebarWithHeader from '../home/sidebard'
  import TypeChambre from './TypeChambre'
  import Chambre from './Chambre'
import PhotoTypeChambre from './ImageTypeChambre'
  
  
  const GestionChambre = (props) => {
    const [isUpdate, setIsUpdate] = useState({
      typeChambre: false,
    })
    const [typeChambres, setTypeChambres] = useState([])
    const [chambres, setChambres] = useState([])
    const [photoTypeChambres, setPhotoTypeChambres] = useState([])

    const tabChambre = [
      {
        label: 'Gestion Chambre',
        content: <Chambre
        getChambres={setChambres}
        typeChambres={typeChambres}
        />,
      },
      {
        label: 'Gestion Type Chambre',
        content: <TypeChambre 
        getTypeChambres={setTypeChambres}
        />,
      },
  
      {
        label: 'Gestion Image Type Chambre',
        content: <PhotoTypeChambre
        getChambres={setPhotoTypeChambres}
        typeChambres={typeChambres}

        />,
      },
      //   {
      //     label: 'Gestion commune',
      //     content:<Commune
      //     villesIn={villes}
      //     />,
      // },
    ]
  
// useEffect(() => {
  
// }, [typeChambres])


  
  
    return (
      <SidebarWithHeader>
        <DataTabs data={tabChambre} />
      </SidebarWithHeader>
  
    )
  }
  export default GestionChambre