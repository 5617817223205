import {
  FormErrorMessage, Radio, RadioGroup, HStack, FormControl, FormLabel, Input, useColorModeValue as mode
  , NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import * as React from 'react'

export const NumberField = React.forwardRef((props, ref) => {
  const { liste, label, Controller,params, control,isRequired, errors, reference, name, ...rest } = props
  return (
    <FormControl position="relative" size="lg">
      <FormLabel
        _focus={{
          color: mode('brand.primary', 'brand.primary'),
        }}
        fontWeight="semibold"
        position="absolute"
        color={mode('gray.600', 'white')}
        top="-7"
        // insetStart="2"
        // bg={{
        //   base: mode('white', 'gray.800'),
        //   md: mode('white', 'gray.700'),
        // }}
        zIndex={2}
        px="2"
        fontSize={{ base: "sm", md: "md" }}
      >
        {!isRequired? label: `${label} *`}
      </FormLabel>
      <Controller
        as={
          <NumberInput 
          {...params}
          size="md" focusBorderColor="brand.secondary" fontSize={{ base: "sm", md: "md" }} 
          {...rest} name={name} 
          ref={reference}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

        }
        name={name}
        control={control}
      />
      <FormErrorMessage>
      {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  )
})
NumberField.displayName = 'NumberField'
