import { Box, FormErrorMessage, Image, Container, FormControl, FormLabel, Input, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import UploadService from '../../../../services/apis/uploadService';

export const ImageField = React.forwardRef((props, ref) => {
  const hiddenFileInput = React.useRef(null);
  const uploadService = new UploadService()
  const { src, onChange, isRequired, values, getValues, label, errors, reference, name, ...rest } = props
  const [source, setSource] = React.useState({
    url: src,
    alt: "",
    uuid: ""
  })
  const handleClick = event => {
    event.preventDefault()
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    var data = new FormData();
    data.append('file', fileUploaded);
    uploadService.uploadImage(data, resp => {
      setSource({
        url: resp.data.url,
        alt: resp.data.nom,
        uuid: resp.data.uuid

      })
    },
      error => {
        console.log(error)
      })


    // onChange(fileUploaded);
  };
  React.useEffect(() => {
    if (values[name]) {
      setSource({
        url: values?.image.url,
        alt: values?.image.nom,
        uuid: values?.image.uuid

      })
    }

    console.log("values", values[name])
  }, [values[name]])

  return (
    <Container centerContent>
      <Box>
        <FormControl
          position="relative" isInvalid={errors[name]}
        >
          <FormLabel
            _focus={{
              color: mode('brand.primary', 'brand.primary'),
            }}
            fontWeight="semibold"
            // position="absolute"
            color={mode('gray.600', 'white')}
            top="-3"
            insetStart="2"
            bg={{
              base: mode('white', 'gray.800'),
              md: mode('white', 'gray.700'),
            }}
            zIndex={2}
            px="2"
            fontSize={{ base: "sm", md: "md" }}
          >
            {!isRequired ? label : `${label} *`}
          </FormLabel>
          <Image
            // borderRadius="full"
            boxSize="250px"
            src={source?.url}
            alt={source?.alt}
            onClick={handleClick}
            borderColor={errors[name] ? "red" : "white"}

          />
          <input
            accept="image/*"
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          <Input
            display="none"
            value={source?.uuid}
            ref={reference}
            size="md"
            focusBorderColor="brand.secondary"
            fontSize="md"
            {...rest}
            name={name}

            type="text"
          />
          <FormErrorMessage>
            {errors[name]?.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </Container>
  )
})
ImageField.displayName = 'ImageField'
