import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    AlertDialogCloseButton
} from '@chakra-ui/react'
import { EditorState, convertToRaw , ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EditorField({
    onClose,
    isOpen,
    defaultValue,
    getText

}) {
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const cancelRef = React.useRef()
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        getText(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    }
React.useEffect(() => {
    console.log("VALUE", defaultValue)
    if (defaultValue){
        
        const blocksFromHtml = htmlToDraft(defaultValue);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState( EditorState.createWithContent(contentState));
    }
    
}, [defaultValue])

    return (
        <>
            {/* <AlertDialog
                motionPreset='slideInBottom'
                minH={"600px"}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogCloseButton />
                    <AlertDialogBody> */}
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            localization={{
                                locale: 'fr',
                              }}
                        />
                    {/* </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Quitter
                        </Button>
                        <Button  colorScheme='red' ml={3}>
                            Annuler
                        </Button> 
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog> */}
        </>
    )
}

export default EditorField