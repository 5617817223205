import {
    Box, Container,
    useToast, Text, Flex, Fade,
    useDisclosure,
    useColorModeValue as mode
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { useEffect, useContext } from 'react'
  import * as yup from "yup";
import { GlobalContext } from '../../context';
import CRUD from '../composants/Crud';
  
  const schema = yup.object().shape({
  
    // telephone: yup.string()
    // .required("Le numéro de téléphone obligatoire")
    // .phone("TD", true, "Le numéro de téléphone invalide"),
    code: yup.string().required("Le code est obligatoire"),
    libelle: yup.string().required("Le libelle est obligatoire"),
    typeService: yup.string().required("Le type de service est obligatoire"),
    // email: yup.string().email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),
  });
   const champsInitial =
  [
    {libelle: null,
      icon: null,
      liste:
    [
      {
        label: "Libelle",
        placeholder: "Entrer le libelle",
        type: "text",
        name: 'libelle',
        isRequired: true
      },
      {
        label: "Code",
        placeholder: "Entrer le code ",
        type: "text",
        name: 'code',
        isRequired: true
      }
  
    ]},
    
  
  ]
  const listeColumn = [
    {
      title: 'Code',
      field: 'code',
    },
    {
      title: 'Libelle',
      field: 'libelle',
    }
  ]

  const TypeContribuable = () => {
    const context = useContext(GlobalContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [data, setData] = React.useState([{
      libelle: "Administarteur",
      code: "Agent",
  
    }])
    const { service, token, dataUser } = context
    const toast = useToast()
    const [isUpdate, setisUpdate] = React.useState(false)
  
    const onSave = (data, setData, isUpdate) => {
  
      if (!isUpdate) {
        // natureRecetteService.addPartie({partie: data}, (resp) => {
        //     natureRecetteService.getPartie((resp) => {
        //         setData(resp.data)
        //         setParties(resp.data)
        //     },
        //         (error) => {
        //             console.error(error);
        //         })
        // },
        //     (error) => {
        //         console.error(error);
  
        //     })
  
      }
      else {
        console.log("dattaaa=>", data)
        // natureRecetteService.updatePartie({updatePartie: data}, (resp) => {
        //     natureRecetteService.getPartie((resp) => {
        //         setData(resp.data)
        //         setParties(resp.data)
        //     },
        //         (error) => {
        //             console.error(error);
        //         })
        // },
        //     (error) => {
        //         console.error(error);
  
        //     })
      }
    }
  
    const onDelete = (data, setData) => {
      // const datas = { externalId:data.meta.uid};
      // console.log("datas",datas)
      // natureRecetteService.deletePartie(datas, (resp) => {
      //         natureRecetteService.getPartie((resp) => {
      //                 setData(resp.data)
      //                 setParties(resp.data)
      //             },
      //             (error) => {
      //                 console.error(error);
      //             })
      //     },
      //     (error) => {
      //         console.error(error);
  
      // })
  
    }
  
  
    const onGet = (setData) => {
      setData(data)
      // natureRecetteService.getPartie((resp) => {
  
      //     setData(resp.data)
      //     setParties(resp.data)
      // },
      //     (error) => {
      //         console.error(error);
      //     })
    }
    const updatePartie = (oldPartie, setData) => {
      // natureRecetteService.updatePartie(oldPartie ,(resp) => {
      //         //console.log("datass==>",resp.data)
      //         setData(resp.data)
      //         setParties(resp.data)
      //     },
      //     (error) => {
      //         console.error(error);
      //     })
  
    }
    useEffect(() => {
  
    }, [data])
    return (
      <>
  
       
              <CRUD
                schema={schema}
                champs={champsInitial}
                columns={listeColumn}
                onSave={onSave}
                onDelete={onDelete}
                onGet={onGet}
                hasRightSave={true}
                hasRightDelete={true}
                hasRightUpdate={true}
                title="Type Contribuable"
                icon={null}
                />
           
  
        
  
      </>
    )
  }
  export default TypeContribuable
  