import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  useColorMode,
  colorMode
} from '@chakra-ui/react';
import Footer from '../footer';
import Header from '../header';
import {Banner} from './banniere';
import MotDG from './motdg';
import { Conteneur } from './Conteneur';
import Contact from './contact'
import Clients from './Clients'
import { Animation } from '../composants/Animation';

export default function Accueil() {

  return (
    <Box  >
      <Header/>
      {/* <Animation> */}
      <Banner/>
      {/* </Animation> */}
      {/* <Animation> */}
      <Conteneur 
      titre={"Presentation"}
      >

      <MotDG/>
      </Conteneur>
      {/* </Animation> */}
      <Conteneur 
      titre={"Nos Clients"}
      >
      <Clients/>
      </Conteneur>
      <Conteneur 
      titre={"Nous contacter"}
      >
      <Contact/>
      </Conteneur>
      <Footer/>
    </Box>
  );
}

