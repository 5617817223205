
  import * as React from 'react'
  import { useState, useEffect } from 'react'
  import { GlobalContext } from '../../context'
  import DataTabs from '../composants/DataTabs'
  import SidebarWithHeader from '../home/sidebard'
import TypeReservation from './TypeReservation'
import PrixTypeReservation from './PrixTypeReservation'
import ListeReservation from './ListeReservation'
  
  
  const GestionReservation = (props) => {
    const [isUpdate, setIsUpdate] = useState({
      typeChambre: false,
    })
    const [typeReservations, setTypeReservations] = useState([])
    const [chambres, setChambres] = useState([])

    const tabReservation = [
      {
        label: 'Gestion Reservation',
        content: <ListeReservation/>,
      },
      {
        label: 'Gestion Type Reservation',
        content: <TypeReservation
          getTypeReservations={setTypeReservations}
        />,
      },
  
      {
        label: 'Gestion Prix Type Chambre',
        content: <PrixTypeReservation
        typeReservations={typeReservations}
        />
      },
      //   {
      //     label: 'Gestion commune',
      //     content:<Commune
      //     villesIn={villes}
      //     />,
      // },
    ]
  
// useEffect(() => {
  
// }, [typeChambres])


  
  
    return (
      <SidebarWithHeader>
        <DataTabs data={tabReservation} />
      </SidebarWithHeader>
  
    )
  }
  export default GestionReservation