import { Box, FormErrorMessage, Tooltip, InputGroup,FormHelperText, Image, Container, FormControl, FormLabel, Input, useColorModeValue as mode, InputRightAddon } from '@chakra-ui/react'
import * as React from 'react'
import UploadService from '../../../../services/apis/uploadService';
import { BsCloudDownload, BsCloudUpload } from 'react-icons/bs';
import { RiFileEditFill } from 'react-icons/ri';

export const FichierField = React.forwardRef((props, ref) => {
  const hiddenFileInput = React.useRef(null);
  const uploadService = new UploadService()
  const { src, onChange, isRequired, label, values,helpText, errors, reference, name, ...rest } = props
  const [source, setSource] = React.useState({
    url: src,
    alt: "",
    uuid: ""
  })
  const handleClick = event => {
    event.preventDefault()
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    var data = new FormData();
    data.append('file', fileUploaded);
    uploadService.uploadImage(data, resp => {
      setSource({
        url: resp.data.url,
        alt: resp.data.nom,
        uuid: resp.data.uuid

      })
    },
      error => {
        console.log(error)
      })


    // onChange(fileUploaded);
  };
  React.useEffect(() => {
    console.log("source", source)
  }, [source?.uuid])
  React.useEffect(() => {

    if (values[name]) {
      console.log("values[name]", values[name?.split("_")[0]])
      setSource({
        url: values[name?.split("_")[0]]?.url,
        alt: values[name?.split("_")[0]]?.nom,
        uuid: values[name?.split("_")[0]]?.uuid

      })
    }

    console.log("values", {
      url: values[name?.split("_")[0]]?.url,
      alt: values[name?.split("_")[0]]?.nom,
      uuid: values[name?.split("_")[0]]?.uuid

    })
  }, [values[name]])

  return (
    <Container centerContent>
     
        <FormControl
          isInvalid={errors[name]}
          size={{base:"full", md:"lg"}}
        >
          <FormLabel
            _focus={{
              color: mode('brand.primary', 'brand.primary'),
            }}
            fontWeight="semibold"
            position="absolute"
            color={mode('gray.600', 'white')}
            top="-7"
            // insetStart="2"
            // bg={{
            //   base: mode('white', 'gray.800'),
            //   md: mode('white', 'gray.700'),
            // }}
            zIndex={2}
            px="2"
            fontSize={{ base: "sm", md: "md" }}
          >
            {!isRequired ? label : `${label} *`}
          </FormLabel>
          {/* <Image
            borderRadius="full"
            boxSize="150px"
            src={source.url}
            alt={source.alt}
            onClick={handleClick}
            borderColor={errors[name]?"red":"white"}

          /> */}
          <InputGroup size={{base:"xs", md:"lg"}}>
          <Input
              type="text"
              minW={{base:"xs", md:"lg"}}
              value={source?.alt}
              defaultValue={source?.alt}
              focusBorderColor="brand.secondary"
              fontSize="md"
            />
            <Input
              type="text"
              maxW="md"
              value={source?.uuid}
              ref={reference}
              defaultValue={source?.uuid}
              focusBorderColor="brand.secondary"
              fontSize="md"
              {...rest}
              name={name}
              display={"none"}
            />
            <Input
              // visibility="hidden"
              display={"none"}
              accept="application/pdf"
              ref={hiddenFileInput}
              onChange={handleChange}
              type="file"
              
            />
            <InputRightAddon >
            <Tooltip label={source?.id ? `Fichier ${source.url} chargé` : "Charger un fichier"}>
              <Box  onClick={() => hiddenFileInput.current.click()}>
                <BsCloudUpload color="brand.primaryLight" size={32} />
              </Box>
              </Tooltip>
            </InputRightAddon>
            {values[name] &&
              <InputRightAddon >
              <Tooltip label={"Telecharger le fichier"}>
                <a href={source?.url}  target="_blank">
                  <Box>
                    <BsCloudDownload color="brand.primaryLight" size={32} />
                  </Box>
                </a>
                </Tooltip>
              </InputRightAddon>}
          </InputGroup >
          {helpText && <FormHelperText>{helpText}</FormHelperText>}
          <FormErrorMessage>
            {errors[name]?.message}
          </FormErrorMessage>
        </FormControl>
  
    </Container>
  )
})
FichierField.displayName = 'FichierField'
