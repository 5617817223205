import {
  Box,
  Button,
  Flex,
  LightMode,
  Stack,
  Text,
  Container,
  IconButton,
  InputGroup,
  InputRightElement,
  Heading,
  FormControl,
  FormLabel,
  Checkbox,
  useColorModeValue,
  Link,
  Image,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import Logo from '../media/images/logo.png'
import * as React from 'react'
import { InputField } from '../composants/InputField'
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
import { GlobalContext } from "../../context";
import { BiShow, BiLowVision } from 'react-icons/bi';
const schema = yup.object().shape({
  motpasse: yup.string().min(8, "8 chiffres minimun").required("Le mot de passe  obligatoire"),
  email: yup.string()//.email("L'adresse mail n'est pas valide").required("L'adresse mail est obligatoire"),

});
export const SigninForm = () => {
  const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm({ resolver: yupResolver(schema) });
  const context = React.useContext(GlobalContext)
  const [isLoad, setIsLoad] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  const { login } = context
  let history = useHistory();
  const onSubmit = async(data) => {
    const isLogin = await login(data).then(response => {
      setIsLoad(!isLoad)
      context.retrieve()
    })
  };
  React.useEffect(() => {
    console.log(isLoad)
    if (context.token)
      history.push("/")
  }, [isLoad])
  return (
    <Flex p={2} flex={1} align={'center'} justify={'center'} as={Box}

    >
      <Box
        rounded="lg"
        shadow="lg"
        px={10}
        pb={20}
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }} as="section">

          {/* <Image
            boxSize='50px'
            objectFit='cover'
            src={Logo}
            alt='Insta logo'
            mx={2}
          /> */}
          <Heading
            my={1}
            //textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            //fontFamily={'heading'}
            bgGradient="linear(to-l, brand.primary, brand.secondary)"
            bgClip="text"
            fontWeight="800"
            
          >
            GES'2ASOEURS
          </Heading>
        </Flex>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >

          <Stack spacing={4}  maxW={'2xl'} >
            {/* <Heading fontSize={'2xl'}>Sign in to your account</Heading> */}
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <InputField type="text" name="mail" reference={register} errors={errors.login} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Mot de passe</FormLabel>
              <InputField type={show ? "text" : "password"} name="motpasse" reference={register} errors={errors.motPasse}
              />
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}>
                <Checkbox>Se souvenir</Checkbox>
                <Link color={useColorModeValue('brand.primary', 'brand.primary')}>Mot de passe oublié?</Link>
              </Stack>
              <Button
                type="submit"
                bgGradient="linear(to-r, brand.primary, brand.secondary)"
                color="white"
                variant={'solid'}>
                connexion
              </Button>
            </Stack>
          </Stack>

        </form>
      </Box>
    </Flex>
  )
}
