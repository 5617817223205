import { Fragment } from 'react';
import {
    Container,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Button,
    Heading,
    useColorModeValue,
    VStack,
    Flex,
    Text,
    Icon,
    Divider,
    ScaleFade
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { GoLocation } from 'react-icons/go';
import { BsPhone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import {FramerChambre} from './Banniere'
import Animate from "../media/images/animate.svg"
const contactOptions = [
    {
        label: 'ADRESSE',
        value: 'Chagoua Non loin du marche de Dembe',
        icon: GoLocation
    },
    {
        label: 'PHONE',
        value: '00235 66266470/92015823',
        icon: BsPhone
    },
    {
        label: 'EMAIL',
        value: 'cabinet.camef@gmail.com',
        icon: HiOutlineMail
    }
];

const Contact = () => {
    return (
        <Container maxW="full" py={10} px={{ base: 5, md: 8 }}
        >
            <Stack spacing={10} direction={{ base: 'column', md: 'row' }}
            // bgImage={Animate}
            // bgColor="brand.darkGrey"
            // bgRepeat="no-repeat"
            // bgSize="cover"
            // py={10}
            
            >
            {/* <SlideFade in={isOpen} offsetY="20px"> */}
          {/* <ScaleFade initialScale={0.9} in={true}> */}
                <FramerChambre/>
                {/* </ScaleFade> */}
                <Stack spacing={10}>
                    <Flex align="center" justify="center" direction="column">
                        {/* <Text 
                        bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                        bgClip={"text"}
                        fontSize="md" textAlign="center">
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                        </Text> */}
                    </Flex>
                    <Stack
                        spacing={{ base: 6, md: 0 }}
                        direction={{ base: 'column', md: 'row' }}
                        justify="space-between"
                    >
                        {contactOptions.map((option, index) => (
                            <Fragment key={index}>
                                <Stack spacing={3} direction="column" justify="center" alignItems="center" px={3}>
                                    <Icon as={option.icon} w={10} h={10} color='brand.secondary' />
                                    <Text 
                                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                                    bgClip={"text"}
                                    fontSize="lg" fontWeight="semibold">
                                        {option.label}
                                    </Text>
                                    <Text 
                                    bgGradient="linear(to-tr, brand.primary, brand.secondary)"
                                    bgClip={"text"}
                                    fontSize="md" textAlign="center">
                                        {option.value}
                                    </Text>
                                </Stack>
                                {contactOptions.length - 1 !== index && (
                                    <Flex d={{ base: 'none', md: 'flex' }}>
                                        <Divider orientation="vertical" />
                                    </Flex>
                                )}
                            </Fragment>
                        ))}
                    </Stack>
                    <VStack
                        as="form"
                        spacing={8}
                        w="100%"
                        bg={useColorModeValue('white', 'gray.700')}
                        rounded="lg"
                        boxShadow="lg"
                        p={{ base: 5, sm: 10 }}
                    >
                        <VStack spacing={4} w="100%">
                            <Stack w="100%" spacing={3} direction={{ base: 'column', md: 'row' }}>
                                <FormControl id="name">
                                    <FormLabel>Nom</FormLabel>
                                    <Input type="text" placeholder="Ahmad" rounded="md" />
                                </FormControl>
                                <FormControl id="email">
                                    <FormLabel>Email</FormLabel>
                                    <Input type="email" placeholder="test@test.com" rounded="md" />
                                </FormControl>
                            </Stack>
                            <FormControl id="subject">
                                <FormLabel>Sujet</FormLabel>
                                <Input type="text" placeholder="Disponible pour un travail?" rounded="md" />
                            </FormControl>
                            <FormControl id="message">
                                <FormLabel>Message</FormLabel>
                                <Textarea size="lg" placeholder="Entrer votre message" rounded="md" />
                            </FormControl>
                        </VStack>
                        <VStack w="100%">
                            <Button
                                bgGradient="linear(to-r, brand.primary, brand.secondary)"
                                color="white"
                                _hover={{
                                    bg: 'brand.secondary'
                                }}
                                rounded="md"
                                w={{ base: '100%', md: 'max-content' }}
                            >
                                Send Message
                            </Button>
                        </VStack>
                    </VStack>
                </Stack>

            </Stack>
        </Container>
    );
};

export default Contact;
