import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue as mode
} from '@chakra-ui/react'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { GlobalContext } from '../../context'
import DataAccordions from '../composants/DataAccordions'
import DataTabs from '../composants/DataTabs'
import SidebarWithHeader from '../home/sidebard'
import Commune from './Commune'
import Departement from './Departement'
import Region from './Region'
import Service from './Service'
import TypeContribuable from './TypeContribuable'
import TypeService from './TypeService'
import Ville from './Ville'


const Parametrage = (props) => {
  const [isUpdate, setIsUpdate] = useState({
    typeService: false,
    service: false,
    region: false,
    departement: false
  })
  const [regions, setRegions] = useState([])
  const [departements, setDepartements] = useState([])
  const [villes, setVilles] = useState([])
  const tabParametrageService = [
    {
      label: 'Gestion type service',
      content: <TypeService />
    },
    {
      label: 'Gestion service',
      content: <Service />,
    },
    {
      label: 'Gestion type contribuable',
      content: <TypeContribuable />,
    },
    {
      label: 'Gestion civilité',
      content: <TypeContribuable />,
    },
  ]
  const tabParametrageAdresse = [
    {
      label: 'Gestion région',
      content: <Region
        getRegions={setRegions}

      />
    },
    {
      label: 'Gestion département',
      content: <Departement
        regions={regions}
        getDepartements={setDepartements}
      />,
    },

    {
      label: 'Gestion ville',
      content: <Ville
        departementsIn={departements}
        getVilles={setVilles}
      />,
    },
    //   {
    //     label: 'Gestion commune',
    //     content:<Commune
    //     villesIn={villes}
    //     />,
    // },
  ]

  const tabParametrage = [
    {
      label: 'Paramétrage service et contribuable',
      content: <DataTabs data={tabParametrageService} />
    },
    {
      label: 'Paramétrage adresse',
      content: <DataTabs data={tabParametrageAdresse} />,
    }
  ]


  return (
    <SidebarWithHeader>
      <DataAccordions data={tabParametrage} />
    </SidebarWithHeader>

  )
}
export default Parametrage